<template>
  <div id="wrapper">
    <div id="header">
      <img :src="require('@/assets/img/logo/full_logo.png')" class="full-logo" />
    </div>
    <div id="main-wrapper">
      <div id="main">
        <img :src="require('@/assets/img/login/login_title.png')" class="login-title" />
        <div class="row-block">
          <el-input placeholder="请输入账号" prefix-icon="el-icon-user" v-model="loginForm.userName"></el-input>
        </div>
        <div class="row-block">
          <el-input :type="showLoginPassword ? 'text' : 'password'" placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="loginForm.password">
            <i slot="suffix" :class="[showLoginPassword ? 'ri-eye-line' : 'ri-eye-close-line']" @click="showLoginPassword = !showLoginPassword"></i>
          </el-input>
        </div>
        <div class="row-block">
          <el-button type="primary" @click="handelLogin">登录</el-button>
        </div>
      </div>
    </div>
    <div id="footer-wrapper">
      <layout-footer id="footer" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLoginPassword: false,
      loginForm: {
        userName: '',
        password: '',
      },
      loading: false,
      redirect: null,
    };
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', "background:url('/static/img/background.png') no-repeat;background-size:100% 100%;background-attachment:fixed;margin:0;");
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style');
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = (route.query && route.query.redirect) || '/';
      },
      immediate: true,
    },
  },
  methods: {
    handelLogin() {
      if (!this.loginForm.userName) {
        this.$baseMessage('用户名不可为空', 'error');
        return;
      }
      if (!this.loginForm.password) {
        this.$baseMessage('密码不可为空', 'error');
        return;
      }
      this.loading = true;
      this.$store
        .dispatch('user/login', this.loginForm)
        .then(() => {
          const routerPath = this.redirect === '/404' || this.redirect === '/401' ? '/' : this.redirect;
          this.$router.push(routerPath).catch(() => {});
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-input--small .el-input__inner,
    .el-button {
      height: 40px !important;
      width: 100%;
      font-size: 14px;
    }

    .el-input__icon {
      font-size: 18px;
    }

    .el-input__suffix {
      line-height: 40px;
      cursor: pointer;

      i {
        font-size: 18px;
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }

    .el-dialog__footer {
      text-align: center;

      .el-button {
        width: 300px;
      }
    }

    .el-link.el-link--default {
      color: white !important;
    }
  }
}

#header {
  height: 13%;
  min-height: 85px;
  position: relative;

  img {
    height: 52px;
    width: 528px;
    position: absolute;
    bottom: 0;
    left: 50px;
  }
}

#main-wrapper {
  height: 77%;
  min-height: 540px;
  position: relative;

  #main {
    height: 440px;
    width: 394px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -220px;
    margin-left: -197px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 110px 20px 20px 20px;

    img {
      position: absolute;
      top: 0;
      left: 50%;
      width: 330px;
      height: 52px;
      margin-left: -165px;
    }

    .row-block {
      margin-bottom: 30px;
      width: 100%;

      &.end-block {
        text-align: right;
        margin-top: 28px;

        span {
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

#footer-wrapper {
  height: 10%;
  min-height: 40px;
  position: relative;
  #footer {
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: white;
    line-height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.08);

    ::v-deep {
      a {
        color: white;
      }
    }
  }
}
</style>
